<template>
  <div class="sys-list">
    <div class="sys-list-c">
      <div class="sys-list-item" @click="change(item.key)" :class="{'sys-list-item-a': item.key === currentSys}" v-for="item in authSysList" :key="item.key">
        {{item.label}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheSysList',
  computed: {
    authSysList () {
      return this.$store.getters.authSysList
    },
    currentSys () {
      return this.$store.getters.currentSys
    },
    currentRouter () {
      return this.$store.state.route.name
    }
  },
  watch: {
    currentRouter: {
      handler (value) {
        this.updateCurrentSys(value)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async change(key) {
      if (key !== this.currentSys) {
        let isNull = this.$store.getters.currentSys === null
        await this.$store.dispatch('setCurrentSys', key)
        if (!isNull) {
          let router = await this.$store.dispatch('getSysUserFirstPage')
          this.$router.push(router)
        }
      }
    },
    updateCurrentSys (routeName) {
      if (!routeName) {
        return
      }
      let sysKey = routeName.split('.')[0]
      if (!this.authSysList.map(v => v.key).includes(sysKey)) {
        sysKey = 'ucenter'
      }
      this.change(sysKey)
    }
  }
}
</script>

<style lang="less" scoped>
.sys-list {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .sys-list-c {
    display: flex;
  }
  .sys-list-item {
    width: 100px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 18px;
    padding-bottom: 5px;
    text-align: center;
    border-bottom: 2px solid rgba(0,0,0,0);
  }
}
.pink-theme {
  .sys-list {
    .sys-list-item {
      color: #fff;
    }
    .sys-list-item:hover {
      color: #fff;
    }
    .sys-list-item-a {
      color: #fff;
      border-color: #F4628F;
    }
  }
}
.blue-theme, .dark-theme {
  .sys-list {
    .sys-list-item {
      color: #fff;
    }
    .sys-list-item:hover {
      border-color: #fff;
    }
    .sys-list-item-a {
      border-color: #fff;
    }
  }
}
.yellow-theme {
  .sys-list {
    .sys-list-item {
      color: #6C573C;
    }
    .sys-list-item:hover {
      border-color: #6C573C;
    }
    .sys-list-item-a {
      border-color: #6C573C;
    }
  }
}
</style>
